<template>
  <div class='card'>
    <Fieldset>
      <template #legend>
        <h3>在线监测设备-检修记录</h3>
      </template>
      <table class='tab-box'>
        <tr>
          <td class='font-bold col-2'>设备名称：</td>
          <td class='col-2'>{{ obj.deviceName }}</td>
          <td class='font-bold col-2'>规格型号：</td>
          <td class='col-2'>{{ obj.deviceModel }}</td>
          <td class='font-bold col-2'>设备编号：</td>
          <td class='col-2'>{{ obj.deviceSeq }}</td>
        </tr>
        <tr>
          <td class='font-bold col-2'>安装时间：</td>
          <td class='col-4' colspan='2'>{{ obj.installDate }}</td>
          <td class='font-bold col-2'>安装地点：</td>
          <td class='col-4' colspan='3'>{{ obj.installAddress }}</td>
        </tr>
        <tr>
          <td class='font-bold'>维护管理单位</td>
          <td colspan='5'>{{obj.maintainer}}</td>
        </tr>

        <tr>
          <td class='font-bold' rowspan='2'>故障情况及发生时间</td>
          <td class='font-bold' >时间</td>
          <td colspan='4'>{{obj.faultStartTime +' —— '+ obj.faultStopTime}}</td>
        </tr>
        <tr>
          <td class='font-bold' >故障描述</td>
          <td colspan='4'>{{obj.faultDesc}}</td>
        </tr>

        <tr>
          <td class='font-bold' rowspan='2'>处理过程及结果</td>
          <td class='font-bold' >过程</td>
          <td colspan='4'>{{obj.processDesc}}</td>
        </tr>
        <tr>
          <td  class='font-bold' >结果</td>
          <td colspan='4'>{{obj.resultDesc}}</td>
        </tr>

        <tr>
          <td class='font-bold'  rowspan='2'>正常投入使用时间</td>
          <td class='font-bold' >时间</td>
          <td colspan='4'>{{obj.normalTime}}</td>
        </tr>
        <tr>
          <td class='font-bold' >备注</td>
          <td colspan='4'>
            <span>{{obj.remark}}</span>
            <div class='mt-2'>
              <Image :preview='true' :src='pic' alt='图片' v-for='pic in obj.pics' :key='pic' width='30' height='30'/>
            </div>
          </td>
        </tr>

        <tr v-if='obj.sign'>
          <td class='font-bold col-2'>企业签名：</td>
          <td class='col-10' colspan='5'>
              <Image :preview='true' :src='obj.sign' alt='图片' :key='obj.sign' width='40' height='30'/>
          </td>
        </tr>
        <tr>
          <td class='font-bold col-2'>维护人员：</td>
          <td class='col-4' colspan='2'>{{ obj.operator }}</td>
          <td class='font-bold col-2'>填表时间：</td>
          <td class='col-4' colspan='2'>{{ processItem.gmtCreate }}</td>
        </tr>
        <tr>
          <td class='font-bold col-2'>审批意见：</td>
          <td class='col-10' colspan='5'>{{ formatApproveResult(approveInfo.approveResult) }}</td>
        </tr>
        <tr>
          <td class='font-bold col-2'>审批人：</td>
          <td class='col-4' colspan='2'>{{ approveInfo.approveUserName }}</td>
          <td class='font-bold col-2'>审批时间：</td>
          <td class='col-4' colspan='2'>{{ formatTsToDateStr(approveInfo.approveDate) }}</td>
        </tr>
      </table>
    </Fieldset>
<!--    <div class='mt-2 flex flex-row justify-content-between' v-if='taskInfo.currentTaskStatus === 4'>-->
<!--      <Button style='width: 40%' class='p-button-danger' label='驳回表单' @click='rejectProcess($event)' />-->
<!--      <Button style='width: 40%' class='p-button-success' label='审核通过' @click='passProcess($event)' />-->
<!--    </div>-->
<!--    {{ processItem }}-->
  </div>
</template>
<script>
import dayjs from 'dayjs';

export default {
  props: {
    processItem: {
      type: Object,
      default: null,
    },
    taskInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      obj: {},
      approveInfo: {},
    };
  },
  taskService: null,
  created() {
  },
  mounted() {
    this.obj = JSON.parse(this.processItem.formContext);
    this.approveInfo.approveResult = this.processItem.approveResult;
    this.approveInfo.approveUserName = this.processItem.approveUserName;
    this.approveInfo.approveDate = this.processItem.approveDate;
  },
  methods: {
    formatCellValue(v) {
      if (v === '2') return '正常';
      if (v === '3') return '异常';
      return '/';
    },
    formatTsToDateStr(ts) {
      if (ts === null) return '';
      return dayjs(ts).format('YYYY-MM-DD HH:mm:ss');
    },
    formatApproveResult(r) {
      if (r === 0) return '驳回';
      if (r === 1) return '通过';
      return '';
    },
  },
};

</script>

<style scoped>
.tab-box, .tab-box td {
  border: 1px solid black;
  border-collapse: collapse; /* 让边框合并，不让出现双边框*/
}

.tab-box {
  width: 100%;
}

.tab-box td {
  flex: 1; /*让每个单元格宽度一样*/
  height: 30px;
  padding-left: 10px;
  align-items: center; /*让单元格文字垂直居中*/
  color: #606266;
}

.col1 {
  width: 15%;
}

.col2 {
  width: 40%;
}

.col3 {
  width: 20%;
}

.col4 {
  width: 25%;
}

::v-deep(.p-badge) {
  font-size: 1rem;
  font-weight: 500;
  min-width: 1rem;
  height: 1rem;
  line-height: 1.429rem;
  background: none;
  color: grey;
  transform: translate(0%, -50%);
}
</style>
