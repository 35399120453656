<template>
  <div class='card'>
    <Fieldset>
      <template #legend>
        <h3>在线监测设备-易耗品更换记录</h3>
      </template>
      <table class='tab-box'>
<!--        <tr>-->
<!--          <td class='font-bold col-2'>设备名称：</td>-->
<!--          <td class='col-2'>{{ obj.deviceName }}</td>-->
<!--          <td class='font-bold col-2'>规格型号：</td>-->
<!--          <td class='col-2'>{{ obj.deviceModel }}</td>-->
<!--          <td class='font-bold col-2'>设备编号：</td>-->
<!--          <td class='col-2'>{{ obj.deviceSeq }}</td>-->
<!--        </tr>-->
        <tr>
          <td class='font-bold text-center'>维护管理单位：</td>
          <td class='' colspan='2'>{{ obj.maintainer }}</td>
          <td class='font-bold text-center'>安装地点：</td>
          <td class='' colspan='3'>{{ obj.installAddress}}</td>
        </tr>
        <tr>
          <td class='font-bold  text-center'>因子名称</td>
          <td class='font-bold  text-center'>易耗品名称</td>
          <td class='font-bold  text-center'>规格型号</td>
          <td class='font-bold  text-center'>单位</td>
          <td class='font-bold  text-center'>数量</td>
          <td class='font-bold  text-center'>更换原因</td>
          <td class='font-bold  text-center'>附图</td>
        </tr>
<!--        {{obj.records}}-->
        <tr v-for='record in obj.records' :key='record'>
          <td>{{record.pol??record.deviceName}}</td>
          <td>{{record.yhpName}}</td>
          <td>{{record.yhpModel}}</td>
          <td>{{record.yhpUnit}}</td>
          <td>{{record.yhpCount}}</td>
          <td>{{record.yhpRemark}}</td>
          <td>
            <Image class='ml-2' :preview='true' :src='pic' alt='图片' v-for='pic in record.pics??[]' :key='pic' width='30'
                   height='30' />
          </td>
        </tr>
        <tr v-if='obj.pics !== null && obj.pics.length > 0'>
          <td class='font-bold'>附图：</td>
          <td class='' colspan='5'>
            <div class='mt-2'>
              <Image :preview='true' :src='pic' alt='图片' v-for='pic in obj.pics' :key='pic' width='30' height='30'/>
            </div>
          </td>
        </tr>
        <tr v-if='obj.sign'>
          <td class='font-bold '>企业签名：</td>
          <td class='' colspan='6'>
              <Image :preview='true' :src='obj.sign' alt='图片' :key='obj.sign' width='40' height='30'/>
          </td>
        </tr>
        <tr>
          <td class='font-bold'>维护人员：</td>
          <td class='' colspan='3'>{{ obj.operator }}</td>
          <td class='font-bold '>填表时间：</td>
          <td class='' colspan='2'>{{ processItem.gmtCreate }}</td>
        </tr>
        <tr>
          <td class='font-bold '>审批意见：</td>
          <td class='' colspan='6'>{{ formatApproveResult(approveInfo.approveResult) }}</td>
        </tr>
        <tr>
          <td class='font-bold '>审批人：</td>
          <td class='' colspan='3'>{{ approveInfo.approveUserName }}</td>
          <td class='font-bold '>审批时间：</td>
          <td class='' colspan='2'>{{ formatTsToDateStr(approveInfo.approveDate) }}</td>
        </tr>
      </table>
    </Fieldset>
<!--    <div class='mt-2 flex flex-row justify-content-between' v-if='taskInfo.currentTaskStatus === 4'>-->
<!--      <Button style='width: 40%' class='p-button-danger' label='驳回表单' @click='rejectProcess($event)' />-->
<!--      <Button style='width: 40%' class='p-button-success' label='审核通过' @click='passProcess($event)' />-->
<!--    </div>-->
<!--    {{ processItem }}-->
  </div>
</template>
<script>
import dayjs from 'dayjs';

export default {
  props: {
    processItem: {
      type: Object,
      default: null,
    },
    taskInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      obj: {},
      approveInfo: {},
    };
  },
  taskService: null,
  created() {
    this.obj = JSON.parse(this.processItem.formContext);
  },
  mounted() {
    this.approveInfo.approveResult = this.processItem.approveResult;
    this.approveInfo.approveUserName = this.processItem.approveUserName;
    this.approveInfo.approveDate = this.processItem.approveDate;
  },
  methods: {
    formatCellValue(v) {
      if (v === '2') return '正常';
      if (v === '3') return '异常';
      return '/';
    },
    formatTsToDateStr(ts) {
      if (ts === null) return '';
      return dayjs(ts).format('YYYY-MM-DD HH:mm:ss');
    },
    formatApproveResult(r) {
      if (r === 0) return '驳回';
      if (r === 1) return '通过';
      return '';
    },
  },
};

</script>

<style scoped>
.tab-box, .tab-box td {
  border: 1px solid black;
  border-collapse: collapse; /* 让边框合并，不让出现双边框*/
}

.tab-box {
  width: 100%;
}

.tab-box td {
  flex: 1; /*让每个单元格宽度一样*/
  height: 30px;
  padding-left: 10px;
  align-items: center; /*让单元格文字垂直居中*/
  color: #606266;
}

.col1 {
  width: 15%;
}

.col2 {
  width: 40%;
}

.col3 {
  width: 20%;
}

.col4 {
  width: 25%;
}

::v-deep(.p-badge) {
  font-size: 1rem;
  font-weight: 500;
  min-width: 1rem;
  height: 1rem;
  line-height: 1.429rem;
  background: none;
  color: grey;
  transform: translate(0%, -50%);
}
</style>
