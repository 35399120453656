<template>
  <div class='card'>
    <Fieldset>
      <template #legend>
        <h3>在线监测设备-盲样考核记录</h3>
      </template>
      <table class='tab-box'>
        <tr>
          <td class='font-bold '>企业名称：</td>
          <td class='' colspan='4'>{{ obj.siteNameFixed }}</td>
          <td class='font-bold '>日期：</td>
          <td class='' colspan='4'>{{ obj.deviceName }}</td>
        </tr>
        <tr>
          <td class='font-bold text-center' style='width:10%'>因子</td>
          <td class='font-bold text-center' style='width:10%'>品牌</td>
          <td class='font-bold text-center' style='width:10%'>型号</td>
          <td class='font-bold text-center' style='width:10%'>时间</td>
          <td class='font-bold text-center' style='width:10%'>在线测量数据</td>
          <td class='font-bold text-center' style='width:10%'>盲样浓度</td>
          <td class='font-bold text-center' style='width:10%'>误差</td>
          <td class='font-bold text-center' style='width:10%'>结果</td>
          <td class='font-bold text-center' style='width:10%'>备注</td>
          <td class='font-bold text-center' style='width:10%'>附图</td>
        </tr>

        <tr v-for='record in obj.records' :key='record'>
          <td>{{ record.pol }}</td>
          <td>{{ record.vendorName }}</td>
          <td>{{ record.deviceModel }}</td>
          <td>{{ record.syTime }}</td>
          <td>{{ record.syData }}</td>
          <td style='padding:0'>
            <inputText v-if='record.pol !== undefined' v-model='record.myNd' style='width: 100%;height:100%;' />
          </td>
          <td style='padding:0'>
            <inputText v-if='record.pol !== undefined' v-model='record.myWc' style='width: 100%;height:100%;' />
          </td>
          <td style='padding:0'>
            <Dropdown class='text-center' v-if='record.pol !== undefined' v-model='record.myResult'
                      :options="['通过','未通过']" placeholder='选择状态' style='width: 100%;height:100%;' />
          </td>
          <td>{{ record.remark }}</td>
          <td>
            <Image class='ml-2' :preview='true' :src='pic' alt='图片' v-for='pic in record.pics' :key='pic' width='30'
                   height='30' />
          </td>
        </tr>
        <tr>
          <td class='font-bold '>维护人员：</td>
          <td class='' colspan='4'>{{ obj.operator }}</td>
          <td class='font-bold '>填表时间：</td>
          <td class='' colspan='4'>{{ processItem.gmtCreate }}</td>
        </tr>
        <tr>
          <td class='font-bold '>审批意见：</td>
          <td class='' colspan='9'>{{ formatApproveResult(approveInfo.approveResult) }}</td>
        </tr>
        <tr>
          <td class='font-bold '>审批人：</td>
          <td class='' colspan='4'>{{ approveInfo.approveUserName }}</td>
          <td class='font-bold '>审批时间：</td>
          <td class='' colspan='4'>{{ formatTsToDateStr(approveInfo.approveDate) }}</td>
        </tr>
      </table>
    </Fieldset>
<!--    <div class='mt-2 flex flex-row justify-content-between' v-if='taskInfo.currentTaskStatus === 4'>-->
<!--      <Button style='width: 40%' class='p-button-danger' label='驳回表单' @click='rejectProcess($event)' />-->
<!--      <Button style='width: 40%' class='p-button-success' label='审核通过' @click='passProcess($event)' />-->
<!--    </div>-->
    <!--    {{ obj.records }}-->
<!--    {{ processItem }}-->
  </div>
</template>
<script>
import dayjs from 'dayjs';

export default {
  props: {
    processItem: {
      type: Object,
      default: null,
    },
    taskInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      obj: {},
      approveInfo: {},
    };
  },
  taskService: null,
  created() {
  },
  mounted() {
    this.obj = JSON.parse(this.processItem.formContext);
    this.approveInfo.approveResult = this.processItem.approveResult;
    this.approveInfo.approveUserName = this.processItem.approveUserName;
    this.approveInfo.approveDate = this.processItem.approveDate;
  },
  methods: {
    formatCellValue(v) {
      if (v === '2') return '正常';
      if (v === '3') return '异常';
      return '/';
    },
    formatTsToDateStr(ts) {
      if (ts === null) return '';
      return dayjs(ts).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};

</script>

<style scoped>
.tab-box, .tab-box td {
  border: 1px solid black;
  border-collapse: collapse; /* 让边框合并，不让出现双边框*/
}

.tab-box {
  width: 100%;
}

.tab-box td {
  flex: 1; /*让每个单元格宽度一样*/
  height: 30px;
  padding-left: 10px;
  align-items: center; /*让单元格文字垂直居中*/
  color: #606266;
}

.col1 {
  width: 15%;
}

.col2 {
  width: 40%;
}

.col3 {
  width: 20%;
}

.col4 {
  width: 25%;
}

::v-deep(.p-badge) {
  font-size: 1rem;
  font-weight: 500;
  min-width: 1rem;
  height: 1rem;
  line-height: 1.429rem;
  background: none;
  color: grey;
  transform: translate(0%, -50%);
}

::v-deep(.p-dropdown-trigger) {
  display: none;
}
</style>
