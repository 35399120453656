<template>
    <div class='card'>
        <Fieldset>
            <template #legend>
                <h3>站房基础设施维护记录</h3>
            </template>
            <table class='tab-box'>
                <tr>
                    <td class='font-bold col1'>企业名称：</td>
                    <td class='col2'>{{ obj.enterpriseName + ' - ' + obj.siteName }}</td>
                    <td class='font-bold col3'>运行单位：</td>
                    <td class='col4'>{{ obj.maintainer }}</td>
                </tr>
                <tr>
                    <td class='font-bold text-center col1'>项目</td>
                    <td class='font-bold text-center col2'>内容</td>
                    <td class='font-bold text-center col3'>巡检结果</td>
                    <td class='font-bold text-center col4'>备注</td>
                </tr>

                <tr v-for='(item,index) in obj.维护预备' :key='item'>
                    <td class='col1' :rowspan='obj.维护预备.length' v-if='index === 0'>维护预备</td>
                    <td class='col2'>
                        {{ item.name }}
                        <span class='text-sm'>({{ item.checkPeriod }})</span>
                    </td>
                    <td class='col3'>{{ formatCellValue(item.value) }}</td>
                    <td class='col4'>
                        {{ item.remark }}
                        <div>
                            <Image class='ml-2' :preview='true' :src='pic' alt='图片' v-for='pic in item.pics' :key='pic'
                                   width='30' height='30' />
                        </div>
                    </td>
                </tr>

                <tr v-for='(item,index) in obj.辅助设备检查' :key='item'>
                    <td class='col1' :rowspan='obj.辅助设备检查.length' v-if='index === 0'>辅助设备检查</td>
                    <td class='col2'>
                        {{ item.name }}
                        <span class='text-sm'>({{ item.checkPeriod }})</span>
                    </td>
                    <td class='col3'>{{ formatCellValue(item.value) }}</td>
                    <td class='col4'>
                        {{ item.remark }}
                        <div>
                            <Image class='ml-2' :preview='true' :src='pic' alt='图片' v-for='pic in item.pics' :key='pic'
                                   width='30' height='30' />
                        </div>
                    </td>
                </tr>

                <tr v-for='(item,index) in obj.采样系统检查' :key='item'>
                    <td class='col1' :rowspan='obj.采样系统检查.length' v-if='index === 0'>采样系统检查</td>
                    <td class='col2'>
                        {{ item.name }}
                        <span class='text-sm'>({{ item.checkPeriod }})</span>
                    </td>
                    <td class='col3'>{{ formatCellValue(item.value) }}</td>
                    <td class='col4'>
                        {{ item.remark }}
                        <div>
                            <Image class='ml-2' :preview='true' :src='pic' alt='图片' v-for='pic in item.pics' :key='pic'
                                   width='30' height='30' />
                        </div>
                    </td>
                </tr>

                <tr v-for='(item,index) in obj.数据采集传输系统' :key='item'>
                    <td class='col1' :rowspan='obj.数据采集传输系统.length' v-if='index === 0'>数据采集传输系统</td>
                    <td class='col2'>
                        {{ item.name }}
                        <span class='text-sm'>({{ item.checkPeriod }})</span>
                    </td>
                    <td class='col3'>{{ formatCellValue(item.value) }}</td>
                    <td class='col4'>
                        {{ item.remark }}
                        <div>
                            <Image class='ml-2' :preview='true' :src='pic' alt='图片' v-for='pic in item.pics' :key='pic'
                                   width='30' height='30' />
                        </div>
                    </td>
                </tr>

                <tr v-for='(item,index) in obj.水污染源在线监测仪器' :key='item'>
                    <td class='col1' :rowspan='obj.水污染源在线监测仪器.length' v-if='index === 0'>水污染源在线监测仪器</td>
                    <td class='col2'>
                        {{ item.name }}
                        <span class='text-sm'>({{ item.checkPeriod }})</span>
                    </td>
                    <td class='col3'>{{ formatCellValue(item.value) }}</td>
                    <td class='col4'>
                        {{ item.remark }}
                        <div>
                            <Image class='ml-2' :preview='true' :src='pic' alt='图片' v-for='pic in item.pics' :key='pic'
                                   width='30' height='30' />
                        </div>
                    </td>
                </tr>
                <tr v-if='obj.sign'>
                    <td class='font-bold '>企业签名：</td>
                    <td class='' colspan='3'>
                        <Image :preview='true' :src='obj.sign' alt='图片' :key='obj.sign' width='40' height='30' />
                    </td>
                </tr>
                <tr>
                    <td class='font-bold col1'>巡检人员：</td>
                    <td class='col2'>{{ obj.operator }}</td>
                    <td class='font-bold col3'>填表时间：</td>
                    <td class='col4'>{{ processItem.gmtCreate }}</td>
                </tr>
                <tr>
                    <td class='font-bold col1'>审批意见：</td>
                    <td colspan='3'>{{ formatApproveResult(approveInfo.approveResult) }}</td>
                </tr>
                <tr>
                    <td class='font-bold col1'>审批人：</td>
                    <td class='col2'>{{ approveInfo.approveUserName }}</td>
                    <td class='font-bold col3'>审批时间：</td>
                    <td class='col4'>{{ formatTsToDateStr(approveInfo.approveDate) }}</td>
                </tr>
            </table>
        </Fieldset>
        <!--      <div class='mt-2 flex flex-row justify-content-between' v-if='taskInfo.currentTaskStatus === 4'>-->
        <!--        <Button style='width: 40%' class='p-button-danger' label='驳回表单' @click='rejectProcess($event)'/>-->
        <!--        <Button style='width: 40%' class='p-button-success' label='审核通过' @click='passProcess($event)'/>-->
        <!--      </div>-->
        <!--    {{processItem}}-->
    </div>
</template>
<script>
import dayjs from 'dayjs';

export default {
    props: {
        processItem: {
            type: Object,
            default: null,
        },
        taskInfo: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            obj: {},
            approveInfo: {},
        };
    },
    created() {
        this.obj = JSON.parse(this.processItem.formContext);
    },
    mounted() {
        this.approveInfo.approveResult = this.processItem.approveResult;
        this.approveInfo.approveUserName = this.processItem.approveUserName;
        this.approveInfo.approveDate = this.processItem.approveDate;
    },
    methods: {
        formatCellValue(v) {
            if (v === '2') return '正常';
            if (v === '3') return '异常';
            return '/';
        },
        formatTsToDateStr(ts) {
            if (ts === null) return '';
            return dayjs(ts).format('YYYY-MM-DD HH:mm:ss');
        },
        formatApproveResult(r) {
            if (r === 0) return '驳回';
            if (r === 1) return '通过';
            return '';
        },
    },
};

</script>

<style scoped>
.tab-box, .tab-box td {
    border: 1px solid black;
    border-collapse: collapse; /* 让边框合并，不让出现双边框*/
}

.tab-box {
    width: 100%;
}

.tab-box td {
    flex: 1; /*让每个单元格宽度一样*/
    height: 30px;
    padding-left: 10px;
    align-items: center; /*让单元格文字垂直居中*/
    color: #606266;
}

.col1 {
    width: 15%;
}

.col2 {
    width: 40%;
}

.col3 {
    width: 20%;
}

.col4 {
    width: 25%;
}

::v-deep(.p-badge) {
    font-size: 1rem;
    font-weight: 500;
    min-width: 1rem;
    height: 1rem;
    line-height: 1.429rem;
    background: none;
    color: grey;
    transform: translate(0%, -50%);
}
</style>
