<template>
  <div class='card'>
    <Fieldset>
      <template #legend>
        <h3>在线监测设备异常说明</h3>
      </template>
      <table class='tab-box'>
        <tr>
          <td class='font-bold col-3 text-center'>企业名称</td>
          <td class='col-9 text-center' colspan='3'>{{ obj.siteNameFixed }}</td>
        </tr>
        <tr>
          <td class='font-bold col-3 text-center'>运营公司</td>
          <td class='col-9 text-center' colspan='3'>{{ obj.maintainer }}</td>
        </tr>
        <tr>
          <td class='font-bold col-3 text-center'>运营负责人</td>
          <td class='col-3 text-center'>{{obj.operator}}</td>
          <td class='font-bold col-3 text-center'>联系电话</td>
          <td class='col-3 text-center'>{{obj.operatorPhone}}</td>
        </tr>
        <tr>
          <td class='font-bold col-3 text-center'>异常因子</td>
          <td class='col-9 text-center' colspan='3'>{{ obj.factors }}</td>
        </tr>
        <tr>
          <td class='font-bold col-3 text-center'>异常时间</td>
          <td class='col-9 text-center' colspan='3'>{{ obj.startTime +' 至 '+obj.endTime}}</td>
        </tr>
        <tr>
          <td class='font-bold col-3 text-center' style='padding: 20px;'>异常原因</td>
          <td class='col-9 text-center' colspan='3'>{{ obj.reason }}</td>
        </tr>
        <tr>
          <td class='font-bold col-3 text-center'>恢复结果</td>
          <td class='col-9 text-center' colspan='3'>{{ obj.result }}</td>
        </tr>
        <tr>
          <td class='font-bold col-3 text-center'>恢复时间</td>
          <td class='col-9 text-center' colspan='3'>{{ obj.resumeTime }}</td>
        </tr>
        <tr>
          <td class='font-bold col-3 text-center'>附图</td>
          <td class='col-9 text-center' colspan='3'>
            <Image :preview='true' :src='pic' alt='图片' v-for='pic in obj.pics' :key='pic' width='30' height='30'/>
          </td>
        </tr>

        <tr>
          <td class='font-bold col-2 text-center'>维护人员：</td>
          <td class='col-4 text-center' colspan='1'>{{ obj.operator }}</td>
          <td class='font-bold col-2 text-center'>填表时间：</td>
          <td class='col-4 text-center' colspan='1'>{{ processItem.gmtCreate }}</td>
        </tr>
        <tr>
          <td class='font-bold col-2 text-center'>审批意见：</td>
          <td class='col-10 text-center' colspan='3'>{{ formatApproveResult(approveInfo.approveResult) }}</td>
        </tr>
        <tr>
          <td class='font-bold col-2 text-center'>审批人：</td>
          <td class='col-4 text-center' colspan='1'>{{ approveInfo.approveUserName }}</td>
          <td class='font-bold col-2 text-center'>审批时间：</td>
          <td class='col-4 text-center' colspan='1'>{{ formatTsToDateStr(approveInfo.approveDate) }}</td>
        </tr>
      </table>
    </Fieldset>
<!--    <div class='mt-2 flex flex-row justify-content-between' v-if='taskInfo.currentTaskStatus === 4'>-->
<!--      <Button style='width: 40%' class='p-button-danger' label='驳回表单' @click='rejectProcess($event)' />-->
<!--      <Button style='width: 40%' class='p-button-success' label='审核通过' @click='passProcess($event)' />-->
<!--    </div>-->
<!--    {{ processItem }}-->
  </div>
</template>
<script>
import dayjs from 'dayjs';

export default {
  props: {
    processItem: {
      type: Object,
      default: null,
    },
    taskInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      obj: {},
      approveInfo: {},
    };
  },
  taskService: null,
  created() {
  },
  mounted() {
    this.obj = JSON.parse(this.processItem.formContext);
    this.approveInfo.approveResult = this.processItem.approveResult;
    this.approveInfo.approveUserName = this.processItem.approveUserName;
    this.approveInfo.approveDate = this.processItem.approveDate;
  },
  methods: {
    formatCellValue(v) {
      if (v === '2') return '正常';
      if (v === '3') return '异常';
      return '/';
    },
    formatTsToDateStr(ts) {
      if (ts === null) return '';
      return dayjs(ts).format('YYYY-MM-DD HH:mm:ss');
    },
    formatApproveResult(r) {
      if (r === 0) return '驳回';
      if (r === 1) return '通过';
      return '';
    },
  },
};

</script>

<style scoped>
.tab-box, .tab-box td {
  border: 1px solid black;
  border-collapse: collapse; /* 让边框合并，不让出现双边框*/
}

.tab-box {
  width: 100%;
}

.tab-box td {
  flex: 1; /*让每个单元格宽度一样*/
  height: 30px;
  padding-left: 10px;
  align-items: center; /*让单元格文字垂直居中*/
  color: #606266;
}

.col1 {
  width: 15%;
}

.col2 {
  width: 40%;
}

.col3 {
  width: 20%;
}

.col4 {
  width: 25%;
}

::v-deep(.p-badge) {
  font-size: 1rem;
  font-weight: 500;
  min-width: 1rem;
  height: 1rem;
  line-height: 1.429rem;
  background: none;
  color: grey;
  transform: translate(0%, -50%);
}
</style>
